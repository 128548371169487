<template>
  <div>
    <el-table
      :data="data"
      stripe
    >
      <el-table-column
        label="Count"
        prop="count"
      >
        <template slot-scope="{ row }">
          {{ row.count | numeral }}
        </template>
      </el-table-column>
      <el-table-column
        label="Visit Date"
        prop="visit_date"
        width="120"
      />
    </el-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    data: { type: Array, default: () => ([]) },
  },

  computed: {
    ...mapGetters('user', [
      'isInternal',
    ]),
  },
};
</script>

<style lang="scss" scoped>
@import '~$element';

.pub-tables {
  display: flex;
  margin-top: 2em;

  .future-pubs {
    flex: 1;
  }

  .past-pubs {
    flex: 3;
    margin-left: 1em;
  }
}

.table-header {
  font-weight: bold;
}

.fa-check-circle {
  color: $--color-success;
}

.fa-exclamation-circle {
  color: $--color-danger;
}
</style>
