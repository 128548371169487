<template>
  <div v-loading.fullscreen="busy">
    <h3>
      {{ pixel.name }}
      <el-tag
        size="mini"
        :type="statusType(pixel.status)"
      >
        {{ pixel.status | statusLabel }}
      </el-tag>
    </h3>
    <div class="org-name">
      {{ orgName }}
    </div>
    <el-row
      v-if="pixel.status !== 'NOT_VERIFIED'"
      class="count-summary"
    >
      <el-col
        class="count"
        :sm="24"
        :md="12"
      >
        <div class="label">
          Count to Date
        </div>
        <div class="value">
          <span v-if="earliestDate">
            {{ countToDate | numeral }}
          </span>
          <span v-else>
            Awaiting Data
          </span>
        </div>
      </el-col>
      <el-col
        class="count"
        :sm="24"
        :md="12"
      >
        <div class="label">
          Date Range
        </div>
        <div
          v-if="earliestDate"
          class="value"
        >
          <div>{{ earliestDate }}</div>
          <div class="date-to">
            to
          </div>
          <div>{{ latestDate }}</div>
        </div>
        <div
          v-else
          class="value"
        >
          Awaiting Data
        </div>
      </el-col>
    </el-row>

    <el-row
      v-if="isInternal"
      class="count-summary"
    >
      <el-col
        class="count"
        :sm="24"
        :md="12"
      >
        <div class="label">
          Pixel ID
        </div>
        <div class="value">
          {{ pixel.pixel_id }}
        </div>
      </el-col>
      <el-col
        class="count"
        :sm="24"
        :md="12"
      >
        <div class="label">
          etPixel ID
        </div>
        <div class="value">
          {{ pixel.embed_code }}
        </div>
      </el-col>
    </el-row>
    <el-row
      v-if="isInternal"
      class="count-summary"
    >
      <el-col
        v-if="pixel.organization"
        class="count"
        :sm="24"
        :md="12"
      >
        <div class="label">
          Organization ID
        </div>
        <div class="value">
          {{ pixel.organization.pixel_organization_id }}
        </div>
      </el-col>
    </el-row>

    <div class="details">
      <el-row :gutter="10">
        <el-col
          class="column cards"
          :sm="24"
          :md="18"
        >
          <el-card shadow="never">
            <div slot="header">
              <span>IP Data</span>
            </div>
            <div>
              <pixel-table
                :data="pixel.data"
              />
            </div>
          </el-card>
        </el-col>
        <el-col
          class="column cards"
          :sm="24"
          :md="6"
        >
          <action-menu
            :actions="actions"
            :item="pixel"
            mode="buttons"
            raised
            @action="handleAction"
          />
          <el-card shadow="never">
            <div slot="header">
              <span>Tags</span>
            </div>
            <div class="tag-list">
              <tag-picker
                :tags="pixel.tags"
                tag-size="medium"
                @add-tag="handleAddTag"
                @remove-tag="handleRemoveTag"
              />
            </div>
          </el-card>

          <el-card
            v-if="isInternal"
            shadow="never"
            class="internal"
          >
            <div slot="header">
              <span>Pixel Partner Override</span>
            </div>
            <p>
              If set, this will override the default pixel partners for this pixel's organization.
            </p>
            <el-select
              v-model="pixel_partners"
              multiple
              @change="handlePixelPartnerChange"
            >
              <el-option
                v-for="partner in pixelPartners"
                :key="partner"
                :label="partner"
                :value="partner"
              />
            </el-select>
          </el-card>
        </el-col>
      </el-row>
    </div>

    <ip-audience-form
      :visible.sync="showAudienceDialog"
      :pixel-id="pixel.id"
    />

    <ip-form-dialog
      :ip="pixel"
      :visible.sync="showEditDialog"
      @save="handleSave"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _get from 'lodash/get';
import {
  dateFormat,
} from '../../../helpers';
import { pixel as pixelApi } from '@/adonis-api';
import TagPicker from '@/components/global/TagPicker.vue';
import IpAudienceForm from '@/components/pixel/IpAudienceForm.vue';
import IpFormDialog from '@/components/pixel/IpFormDialog.vue';
import PixelTable from '@/components/pixel/details/PixelTable.vue';
import ActionMenu from '@/components/global/ActionMenu.vue';
import { getIpMenu } from '@/menus';
import { pixelPartners } from '@/constants';

export default {
  name: 'PagePixelDetail',
  components: {
    ActionMenu,
    IpAudienceForm,
    IpFormDialog,
    PixelTable,
    TagPicker,
  },

  filters: {
    dateFormat(value) {
      return dateFormat({
        date: value,
        formatOverride: 'M/D/YYYY h:mma',
      });
    },
    statusLabel: function (value) {
      switch (value) {
        case 'ACTIVE':
          return 'Active';
        case 'INACTIVE':
          return 'Inactive';
        case 'AWAITING_DATA':
          return 'Verified, awaiting data';
        case 'NOT_VERIFIED':
        default:
          return 'Not verified';
      }
    },
  },

  data() {
    return {
      actions: getIpMenu(this),
      activeTab: 'overview',
      pixel: {},
      pixel_partners: null,
      busy: false,
      downloads: [],
      geoframes: [],
      jobs: [],
      publishSchedule: {},
      tags: [],
      showAudienceDialog: false,
      showEditDialog: false,
    };
  },

  computed: {
    ...mapGetters('mqtt', ['getJobs']),
    ...mapGetters('user', ['hasPermission', 'isInternal']),
    ...mapGetters('settings', ['rangeStart', 'rangeEndDate']),

    countToDate() {
      if (!this.pixel.data) {
        return 0;
      }

      return this.pixel.data.map(d => d.count).reduce((a, b) => a + b, 0);
    },

    earliestDate() {
      if (!this.pixel.data) {
        return false;
      }

      const dates = this.pixel.data.map(d => d.visit_date).sort();

      if (dates.length > 0) {
        return dates[0];
      }

      return false;
    },

    latestDate() {
      if (!this.pixel.data) {
        return false;
      }

      const dates = this.pixel.data.map(d => d.visit_date).sort().reverse();

      if (dates.length > 0) {
        return dates[0];
      }

      return false;
    },

    orgName() {
      return _get(this.pixel.organization, 'name');
    },

    pixelPartners() {
      return pixelPartners;
    },
  },

  watch: {
    '$route.params.id': {
      handler(val) {
        this.init();
      },
      immediate: true,
    },
  },

  beforeDestroy() {
    this.stopSub && this.stopSub();
  },

  methods: {
    statusType (value) {
      switch (value) {
        case 'ACTIVE':
          return 'success';
        case 'INACTIVE':
          return 'info';
        case 'AWAITING_DATA':
        case 'NOT_VERIFIED':
        default:
          return 'warning';
      }
    },
    async handleAction(command) {
      switch (command) {
        case 'addData':
          this.showEditDialog = true;
          break;
        case 'audience':
          this.showAudienceDialog = true;
          break;
        case 'enable':
          this.togglePixels({ ids: [this.pixel.id], on: true });
          this.$message('IP list enabled.');
          break;
        case 'disable':
          this.$confirm(
            `Are you sure you want to disable the IP list "${
              this.pixel.name
            }"?` + ' No data will be ingested for this IP list if disabled.',
            'Disable IP List',
          )
            .then(() => this.togglePixels({ ids: [this.pixel.id], on: false }))
            .then(() => {
              this.$message('IP list disabled.');
            })
            .catch(err => {
              if (err !== 'cancel') {
                this.$reportError(err);
              }
            });
          break;

        default:
          break;
      }
    },

    async init() {
      this.pixel = {};
      this.busy = true;

      try {
        const pixelId = this.$route.params.id;

        this.pixel = await pixelApi.findOne(pixelId);
        this.pixel_partners = this.pixel.pixel_partner_override;
        this.busy = false;
        // this.jobs = await pixelApi.getJobs(audienceId);
      } catch (e) {
        this.busy = false;
        this.$notify.error({
          message:
            'There was a problem fetching IP list information. This issue has been reported.',
        });
        this.$reportError(e);
      }

      this.stopSub = this.$store.subscribeAction(action => {
        if (
          action.type === 'event' &&
          action.payload.eventType === 'SAVED_AUDIENCE'
        ) {
          const { pixel } = action.payload;
          this.pixel = pixel;
        }
      });
    },

    async handleAddTag(name) {
      const response = await pixelApi.addTag(this.pixel.id, { name });

      this.pixel.tags.push(response.data);
      this.showSaveMsg();
    },

    async handleRemoveTag(tagId) {
      await pixelApi.removeTag(this.pixel.id, tagId);

      const index = this.pixel.tags.findIndex(tag => tag.id === tagId);

      index >= 0 && this.pixel.tags.splice(index, 1);
      this.showSaveMsg();
    },

    async handlePixelPartnerChange() {
      const response = await pixelApi.update(this.pixel.id, { providers: this.pixel_partners });
      this.showSaveMsg();
    },

    showSaveMsg() {
      this.$message({
        message: 'IP list updated.',
        type: 'success',
      });
    },

    async togglePixels(args) {
      if (args.on) {
        pixelApi.activate(args.ids);
      } else {
        pixelApi.deactivate(args.ids);
      }

      this.pixel.active = args.on;
      this.pixel.status = args.on ? 'AWAITING_DATA' : 'INACTIVE';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$element';

.org-name {
  color: #666;
  font-size: 0.9em;
  margin-top: -1em;
}

.count-summary {
  border-bottom: 1px solid #ddd;
  margin: 0 auto;
  max-width: 860px;
  padding-bottom: 1em;
}

.count {
  color: #444;
  margin-top: 1.5em;
  text-align: center;

  .label {
    color: #888;
    font-size: 0.75em;
    font-weight: 700;
    text-transform: uppercase;
  }

  .value {
    font-size: 2em;
  }

  .date-to {
    font-size: 0.25em;
    text-transform: uppercase;
  }
}

.details {
  margin-top: 2em;
}

.column:not(:last-of-type) {
  margin-bottom: 10px;
}

.cards > div:not(:last-of-type) {
  margin-bottom: 10px;
}

.embed-code {
  width: calc(100% - 60px);
  margin-right: 1em;
}

.small {
  background: #fcc;
  padding: 1em;
  font-size: .8rem;
  h3 {
    margin: 0;
  }
  p {
    margin-top: .25em;
  }
}

.internal {
  background: rgba(255, 100, 100, 0.15)
}
</style>
